<template>
	<div class="messageContainer">
		<qy-top-nav title="找厂家" />
		<div style="display: flex;background-color: #fff;align-items: center;color:#323233;">
			<div style="padding-left: 10px; margin: 6px 0;position:relative;flex-shrink: 0;;" @click="showProxyPanel = true">
				<span style=" position: relative;  margin-left: 4px;relative;top:-3px;left:-1px; margin-right: 2px">{{ proxyStg.originName||proxyStg.name }}</span>
				<van-icon :name="showProxyPanel ? 'arrow-up' : 'arrow-down'" />
			</div>
			<div style="width: 100px;flex-shrink: 0;margin-left: 4px;" @click="showCatePanel=true">
				<span style=" margin-left: 4px; margin-right: 2px;position: relative;top:-3px;left:-1px;;">{{ cateName }}</span>
				<van-icon :name="showCatePanel ? 'arrow-up' : 'arrow-down'"  />
			</div>
			<div style="flex-grow: 1;">
				<van-search v-model="queryText" shape="round" show-action placeholder="请输入名称">
					<template #action>
						<div @click="onLoad(true)">搜索</div>
					</template>
				</van-search>
			</div>
		</div>
		<van-cell-group class="van-clearfix">
			<van-list v-model="loadMore" :immediate-check="false" :finished="finished" :offset="offset" :finished-text="finishedText" @load="onLoad">
				<div v-for="item in shopList1" :key="item.id" style="background: #fff; border-radius: 5px; margin: 10px 0px">
					<div style="display: flex; padding: 20px 20px 20px 20px" class="productline" @click="gotoShop(item.shopId)">
						<div style="width: 50px; height: 50px; border-radius: 50px; border: 1px solid #ededed; overflow: hidden">
							<img style="width: 50px; height: 50px; border-radius: 50px" :src="getImgUrl(item.shopLogo)" />
						</div>
						<div style="flex-grow: 1; padding-left: 8px">
							<p style="margin: 0px 0 5px 4px; font-weight: bold; font-size: 18px">
								{{ item.shopName }}
							</p>
							<div>
								<span style="font-size: 13px; color: #999999; background: #f7f7f7; margin: 0 4px; padding: 2px 4px" v-for="tag in item.tags">{{ tag }}</span>
							</div>
						</div>
						<van-image :src="imgs['level' + item.level]" style="width: 40px; height: 40px" />
					</div>

					<div class="prolist">
						<p class="watchicon">
							<span class="">
								{{ item.viewNum }}
							</span>
							次浏览
						</p>
						<span class="borderone" @click="gotoShop(item.shopId,1)">产品介绍</span>
						<a style="color: #fff" :href="'tel:' + item.shopPhone">
							<span class="bordertwo">电话咨询</span>
						</a>
					</div>
				</div>
				<van-cell v-for="item in list" is-link :to="'/shop?id=' + item.shopId">
					<template #title>
						<span style="font-size: 16px; faont-weight: normal">{{ item.shopName }}</span>
						<van-image :src="sta01" width="70" height="21" v-if="item.status == '1'" style="position: relative; top: 3px; left: 5px" />
						<!--已通过-->
					</template>
					<template #label>
						<div class="van-multi-ellipsis--l2" style="font-size: 14px">
							{{ item.shopIntro }}
						</div>
					</template>
					<template #icon>
						<van-image :src="getImgUrl(item.shopLogo)" width="70" height="70" style="min-height: 50px; margin-right: 5px; border-radius: 5px; overflow: hidden" />
					</template>
					<template #right-icon>
						<van-image :src="sta03" width="75" height="30" v-if="item.status == null" />
						<!--成为经销商-->
						<van-image :src="sta02" width="50" height="50" v-if="item.status == '0'" />
						<!--待审核-->
					</template>
				</van-cell>
			</van-list>
		</van-cell-group>
		<div style="height: 0;">
			<baidu-map class="map" @ready="onLocation" center="北京" :zoom="15" @locationSuccess="onLocation" @locationError="onLocation">
			  </baidu-map>
		</div>
		<div style="height: 30px; width: 100%"></div>
		<van-action-sheet v-model="showProxyPanel" :actions="proxyList" cancel-text="取消" close-on-click-action @cancel="showProxyPanel = false" @select="onSelectProxy" />
		<van-action-sheet v-model="showCatePanel" :actions="shopCateList" cancel-text="取消" close-on-click-action @cancel="showCatePanel = false" @select="onSelectShopCate" />
	</div>
</template>

<script>
import bottomTabNav from '@/components/bottomTabNav';
import request from '@/utils/request';
import sta01 from '@/assets/images/sta01.png';
import sta02 from '@/assets/images/sta02.png';
import sta03 from '@/assets/images/sta03.png';
import level1Png from '@/assets/index/level1.png';
import level2Png from '@/assets/index/level2.png';
import level3Png from '@/assets/index/level3.png';
export default {
	name: 'ShopList',
	components: { bottomTabNav },
	data() {
		return {
			imgs: {
				level1: level1Png,
				level2: level2Png,
				level3: level3Png
			},
			sta01: sta01,
			sta02: sta02,
			sta03: sta03,
			show: false,
			showStatus: false,
			queryText: '',
			message: '',
			title: '',
			shopName: '',
			shopList1: [],
			shopList2: [],
			list:[],//无用
			cateId:"",
			cateName:"",
			offset: 50,
			loadMore: false,
			finished: false,
			finishedText: '没有更多了',
			pageNum: 1,
			pageSize: 10,
			isRequsting: false,
			showProxyPanel: false,
			showCatePanel: false,
			proxyStg: {},
			shopCateList: [],
			proxyList: [],
		};
	},
	created() {
		this.queryText = this.getUrlParam('keyword') || '';
		this.cateId=this.getUrlParam('cateId') || '-1';
		this.getDefaultProxyStg();
		this.getProxyStgList();
		this.getShopTypeList();
		this.hideShare();
	},
	methods: {
		onLocation(e) {
			this.BMap = e.BMap;
			if (this.BMap) {
				this.refreshLocation(this.BMap,res => {
				  this.position = res.point;
				  this.refreshProxyDistance();
				})
			}
		},
		  
		getDefaultProxyStg() {
			let proxyStg = this.storage.get('proxyStg');
			if (!proxyStg) {
				request.post('/api/proxyStg/latest').then((rs) => {
					if (rs.success) {
						this.proxyStg = rs.data;
						this.storage.set('proxyStg', JSON.stringify(rs.data));
						this.onLoad(true);
					} else {
						Toast.fail(rs.msg);
					}
				});
			} else {
				this.proxyStg = JSON.parse(proxyStg);
				this.onLoad(true);
			}
		},
		getProxyStgList() {
			request.post('/api/proxyStg/list').then((res) => {
				this.proxyList = res;
				this.refreshProxyDistance();
			});
		},
		refreshProxyDistance(){
			if(!this.proxyList||this.proxyList.length<1||!this.position){
				return;
			}
			this.each(this.proxyList,(i,item)=>{
				if(!item.lat||!item.lon){
					return;
				}
				item.distance=this.mapUtil.distance(this.position.lat,this.position.lng,item.lat,item.lon)
				if(!item.distance){
					return;
				}
				item.distance=parseInt(item.distance);
				item.originName=item.name;
				let distanceDesc=item.distance+"米";
				if(item.distance>1000){
					distanceDesc=parseInt(item.distance/1000)+"公里";
				}
				item.name=item.name+"（"+distanceDesc+"）";
			});
			let allProxy=this.proxyList.splice(0,1);
			let proxyList=this.proxyList.sort(function(i1,i2){
				if(!i1.distance){
					return -1;
				}else{
					return i1.distance>i2.distance?1:-1;
				}
			});
			this.proxyList=allProxy.concat(proxyList);
		},
		
		onSelectProxy(item) {
			this.storage.set('proxyStg', JSON.stringify(item));
			this.proxyStg=item;
			this.onLoad(true);
		},
		getShopTypeList(){
			this.doPost("/api/common/dict",{type:"shop_type"},(rs)=>{
				rs.splice(0,0,{dictValue:"-1",dictLabel:"所有行业"})
				this.each(rs,(i,item)=>{
					item.name=item.dictLabel;
					if(this.cateId==item.dictValue){
						this.cateName=item.dictLabel;
					}
				})
				this.shopCateList=rs;
			})
		},
		onSelectShopCate(item){
			this.cateId=item.dictValue;
			this.cateName=item.name;
			this.onLoad(true)
		},
		gotoShop(id,tabId) {
			this.$router.push({ path: '/shop', query: { id: id ,t:tabId||0} });
		},
		
		onLoad(refresh) {
			if(refresh){
				this.shopList1 = [];
				this.shopList2 = [];
				this.pageNum=1;
				this.finished=false;
			}
			//发送请求数据
			const db = {
				queryText: this.queryText,
				pageSize: this.pageSize,
				cateId: this.cateId,
				proxyId:this.proxyStg.id,
				pageNum: this.pageNum
			};
			if (!this.finished) {
				this.getList('/api/shop/queryShopList', db);
			}
		},
		getList(url, db) {
			if(this.isRequsting){
				return;
			}
			this.isRequsting=true;
			let that = this;
			this.Toast.loading();
			request.post(url, db).then((res) => {
				this.isRequsting=false;
				this.Toast.clear();
				if (res.code === 0) {
					if (res.data.length > 0) {
						let shopList1 = that.shopList1,
							shopList2 = that.shopList2;

						for (let i = 0; i < res.data.length; i++) {
							let shop = res.data[i];
							shop.tags = (shop.tags || '').split(',');
							for (let i = shop.tags.length - 1; i >= 0; i--) {
								if (!shop.tags[i]) {
									shop.tags.splice(i, 1);
								}
							}
							shop.level = shop.level || '1';
							shopList1.push(shop);
						}
						that.shopList1 = shopList1;
					}
					//最后一页
					if (!res.hasNextPage) {
						that.finished = true;
						that.loadMore = false;
					} else {
						that.finished = false;
						that.loadMore = false;
						that.pageNum += 1;
					}
				} else {
					that.finished = true;
				}
			});
		}
	}
};
</script>
<style scoped>
.van-search__content--round {
	border: 0px;
}
.van-clearfix {
	background: #f7f7f7;
	padding: 0px 10px;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
}
.productline {
	position: relative;
}
.productline:after {
	content: ' ';
	position: absolute;
	bottom: 0px;
	left: 10px;
	right: 10px;
	height: 1px;
	background: #ededed;
}
.borderone {
	color: #d51c20;
	border: 1px solid #ededed;
	padding: 5px 8px;
	border-radius: 5px;
	line-height: 16px;
}
.bordertwo {
	background-color: #d51c20;
	color: #ffffff;
	border: 1px solid #d51c20;
	padding: 5px 8px;
	margin-left: 6px;
	border-radius: 5px;
	line-height: 16px;
}
.prolist {
	text-align: right;
	padding: 15px 10px;
	font-size: 14px;
	position: relative;
}
.prolist .watchicon {
	position: absolute;
	top: 0px;
	color: #999;
}
</style>
